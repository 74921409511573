
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

interface Notifications {
  limit: number
  notifications: [{
    done: boolean
    notification: {
      alert: object,
      data: {
        cid: number
      }
    }
    pid: number
    receiver: number
    schedule: string
    seen: boolean
    sender: number
    $config: null
  }]
}

export default Vue.extend({
  name: 'Header',
  // middleware: 'auth',
  data () {
    return {
      isMenuOpen: false,
      notifications: {} as Notifications,
      popupError: false,
      error: {},
      $gtm: null
    }
  },
  async fetch () {
    if (this.$auth.loggedIn) {
      await this.getNotifications()
    }
  },
  computed: {
    ...mapGetters({
      isFirstLogin: 'notifications/getIsFirstLogin',
      getCount: 'notifications/getCount'
    }),
    currentUser () {
      if (this.$auth.loggedIn === true) {
        return true
      } else {
        return false
      }
    },
    getUserName() {
      const capitalize = (str) => {
        if (!str) { return '' }
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      }
      console.log('user', this.$auth.user)

      const firstName = capitalize(this.$auth.user.first_name)
      const lastName = capitalize(this.$auth.user.last_name)

      return `${firstName} ${lastName}`
    },
    profileLink () {
      let width = 768
      if (process.browser) {
        width = window.innerWidth
      }
      if (width < 768) {
        return 'user-settings'
      } else {
        return 'user-settings-profile'
      }
    },
    filteredNotifications (): any {
      const filteredArray: any = []
      let prevType = ''
      let prevCid = 0
      if (this.notifications.notifications !== null && this.notifications.notifications !== undefined) {
        for (let i = 0; i < this.notifications.notifications.length; i++) {
          if (this.notifications.notifications[i].notification.alert['loc-key'] === prevType && this.notifications.notifications[i].notification.data.cid === prevCid) {
            filteredArray.push(this.notifications.notifications[i].pid)
          }
          prevType = this.notifications.notifications[i].notification.alert['loc-key']
          prevCid = this.notifications.notifications[i].notification.data.cid
        }
        return this.notifications.notifications.filter(notification => !(filteredArray.includes(notification.pid)))
      } else {
        return this.notifications.notifications
      }
    }
  },
  watch: {
    $route () {
      if (this.$auth.loggedIn && this.$auth.user.locale !== this.$i18n.locale) {
        this.$i18n.setLocale(this.$auth.user.locale)
      }
    },
    currentUser () {
      this.getNotifications()
    }
  },
  mounted () {
    if (this.$auth.loggedIn) {
      setInterval(() => {
        this.getNotifications()
      }, 300000)
    }
  },
  methods: {
    ...mapActions({
      setCount: 'notifications/setCount'
    }),
    redirectToPlatform() {
      window.open('https://business.cving.com/per-le-aziende', '_blank')
    },
    trackMenu (menu:string, type:string) {
      this.$gtm.push({
        event: 'click_menu',
        event_data: {
          menu_location: 'header',
          menu_type: type,
          menu_text: menu,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    },
    track () {
      this.$gtm.push({
        event: 'click_profile',
        event_data: {
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    },
    hidePopup () {
      this.popupError = false
    },
    async getNotifications () {
      /* if (this.$auth.loggedIn) {
        const params = {
          uid: -1,
          index_start: 0,
          limit: 20
        }
        try {
          this.notifications = await this.$axios.$post('/proxyapi/auth/get-user-notifications-v2', params)
          this.countNotifications()
        } catch (error: any) {
          if (error.response !== undefined && error.response.status === 401) {
            this.$auth.logout()
            this.$router.push(this.localePath('login'))
          } else if (error.response) {
            this.popupError = true
            this.error = error.response
          }
        }
      } */
    },
    countNotifications () {
      let counter = 0
      if (this.filteredNotifications !== null) {
        this.filteredNotifications.forEach(function (c: any) {
          if (!c.seen) {
            counter++
          }
        })
      }
      this.setCount(counter)
    }
  }
})
