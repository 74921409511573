
import Vue from 'vue'

export default Vue.extend({
  name: 'CVing',
  middleware: 'firstlogin',
  head () {
    return this.$nuxtI18nHead({ addSeoAttributes: true })
  },
  watch: {
    $route() {
      this.$nuxt.$loading?.start()
      const mainContainer = document.getElementsByClassName('main-container-scroll')[0]

      if (mainContainer) {
        mainContainer.scrollTo({
          top: 0
        })
      }

      if (this.$auth.loggedIn) {
        this.$axios.get('/proxyapi/auth/get-csrf-token')
        this.$axios.get('/proxystorage/auth/get-csrf-token')
      }

      // Aspetta 500ms prima di terminare il caricamento
      setTimeout(() => {
        this.$nuxt.$loading?.finish()
      }, 250)
    }

  },
  mounted () {
    if (this.$auth.loggedIn) {
      this.$axios.get('/proxyapi/auth/get-csrf-token')
      this.$axios.get('/proxystorage/auth/get-csrf-token')
    }
  }
})
